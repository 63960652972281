<script lang="ts">
	import { onMount } from 'svelte';
	interface Props {
		value?: string | undefined;
		label?: string;
		label_for?: string;
		required?: boolean;
		disabled?: boolean;
		focus?: boolean;
		type?: string;
		error?: boolean | undefined;
		name?: string | undefined;
		[key: string]: any;
	}

	let {
		value = $bindable(''),
		label = '',
		label_for = '',
		required = false,
		disabled = false,
		focus = false,
		type = 'text',
		error = false,
		name = undefined,
		...rest
	}: Props = $props();
	function typeAction(node: HTMLInputElement) {
		node.type = type;
	}
	let textField: HTMLInputElement | undefined = $state();
	onMount(() => {
		if (focus) {
			textField?.focus();
		}
	});
</script>

<div {...rest} class="flex flex-col w-full space-y-1">
	{#if label}
		<label class="ml-1 text-sm xl:text-base text-zinc-200" for={label_for}>{label}</label>

		<input
			class="p-1.5 text-zinc-100 border-2 rounded-md bg-zinc-900 border-zinc-600 focus:outline-none focus:ring focus:border-blue-500 focus:bg-black disabled:cursor-not-allowed disabled:bg-zinc-800"
			class:error
			{name}
			use:typeAction
			alt={label}
			id={label_for}
			{required}
			{disabled}
			bind:value
			bind:this={textField}
		/>
	{:else}
		<input
			class="p-1.5 text-zinc-100 border-2 rounded-md bg-zinc-900 border-zinc-600 focus:outline-none focus:ring focus:border-blue-500 focus:bg-black"
			class:error
			{name}
			use:typeAction
			{required}
			{disabled}
			bind:value
			bind:this={textField}
		/>
	{/if}
</div>

<style lang="scss">
	.error {
		border-color: #f07575;
		&:focus {
			outline: none;
			border-color: rgb(250, 69, 69);
			box-shadow: 0 0 0 3px rgba(235 97 97 / 0.3);
		}
	}
</style>
